.skills_container {
  grid-template-columns: repeat(3, 250px);
  column-gap: 1.5rem;
  justify-content: center;
}

.skills_content {
  background-color: var(--container-color);
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  padding: 1rem;
}

.skills_title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.skills_data {
  display: flex;
  align-items: center;
  margin: 0.3rem;
}

.skills_name {
  font-size: 0.8rem;
  font-weight: 500;
}

/* For large devices */
@media screen and (max-width: 992px) {
  .skills_container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .skills_container {
    grid-template-columns: 1fr;
  }

  .skills_content {
    padding: 1.5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .skills_box {
    column-gap: 1.25rem;
  }

  .skills_content {
    margin: 0 1rem;
  }

  .skills_name {
    font-size: var(--small-font-size);
  }
}
