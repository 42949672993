.footer {
  background-color: var(--container-color);
  border-top: 1px solid rgb(0, 0, 0, 0.1);
}

.footer_container {
  padding: 1.5rem 0 6rem;
}

.footer_title,
.footer_link {
  color: var(--title-color);
}

.footer_title {
  text-align: center;
  margin-bottom: 1.5rem;
}

.footer_link:hover {
  color: var(--title-color-dark);
  font-weight: 600;
}

.footer_list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer_social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer_social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.25rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.footer_social-link:hover {
  background-color: var(--title-color-dark);
}

.footer_copy {
  display: block;
  margin: 2rem 0 -4rem 0;
  color: var(--title-color);
  text-align: center;
  font-size: var(--small-font-size);
}
